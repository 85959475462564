import * as React from 'react';
import { Badge, BadgeProps, Stack } from '@cyber-cats/uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { useFeature } from 'hooks/useFeature';
import { Variant, VariantProduct } from '__generated__/graphql';
import { isAppExclusiveValidRange } from 'utils/isAppExclusiveValidRange';

export const INFO_BADGES = [
  'new',
  'customize',
  'personalise',
  'restock',
  'unisex',
  'big-and-tall',
  'plus-size',
  'shop-the-look',
  'limited-edition',
] as const;

export const FAVOURITE_BADGES = [
  'top-rated',
  'most-favorited',
  'best-seller',
  'puma-select',
  'trending',
  'select',
] as const;

export const EXCLUSIVE_BADGES = [
  'puma-store-exclusive',
  'exclusive',
  'app-exclusive',
  'online-exclusive',
] as const;

export const ECO_BADGES = [
  'vegan',
  'sustainable',
  'sustainable-material',
  'recycled',
  'recycled-materials',
  'organic-cotton',
  'bio-based',
] as const;

export const SALE_BADGES = [
  'sale',
  'black-friday',
  'cyber-monday',
  'singles-day-special',
  'christmas-deal',
  'special-offer',
  'last-chance',
  'special-price',
] as const;

export const getBadgeColor = (
  badge: string | BadgeType = ''
): BadgeProps['color'] => {
  const lowerCaseBadge = badge.toLowerCase();

  switch (true) {
    case FAVOURITE_BADGES.includes(lowerCaseBadge as never):
      return 'info';

    case EXCLUSIVE_BADGES.includes(lowerCaseBadge as never):
      return 'muted';

    case ECO_BADGES.includes(lowerCaseBadge as never):
      return 'success';

    case SALE_BADGES.includes(lowerCaseBadge as never):
    case lowerCaseBadge.includes('deal'):
    case lowerCaseBadge.includes('percent'):
      return 'brand';

    case INFO_BADGES.includes(lowerCaseBadge as never):
    case lowerCaseBadge.includes('worn by'):
    case lowerCaseBadge.includes('designed by'):
    default:
      return 'base';
  }
};

export type BadgeType =
  | (typeof INFO_BADGES)[number]
  | (typeof FAVOURITE_BADGES)[number]
  | (typeof EXCLUSIVE_BADGES)[number]
  | (typeof ECO_BADGES)[number]
  | (typeof SALE_BADGES)[number];

type ProductBadgeProps = {
  id?: string | BadgeType;
  label: string;
  icon?: BadgeProps['icon'];
};

export const ProductBadge: React.FC<ProductBadgeProps> = ({
  id,
  label,
  icon,
}: ProductBadgeProps) => (
  <Badge
    color={getBadgeColor(id)}
    icon={icon}
    dataTestId="product-badge"
    label={label.replace(/-/g, ' ')}
  />
);

export const SaleBadge = (props: {
  variant?: Variant | VariantProduct | null;
}) => {
  const t = useTranslate();
  const saleBadgeEnabled = useFeature('SHOW_SALES_BADGE_ON_PRODUCT_IMAGE');
  const percentageOff = props.variant?.percentageDiscountBadge;

  if (!percentageOff || !saleBadgeEnabled) return null;

  const label = t('percentOff', { amount: percentageOff });

  return (
    <Badge
      color="brand"
      label={label}
      data-test-id="product-badge-sale"
      className="absolute top-0 left-0 z-[5]"
    />
  );
};

export const ProductBadges = (props: { variant: Variant }) => {
  const { badges, appOnlyDateTimeFrom, appOnlyDateTimeTo, isAppExclusive } =
    props.variant;
  if (!badges?.length) return null;
  const validRange = isAppExclusiveValidRange(
    isAppExclusive,
    appOnlyDateTimeFrom,
    appOnlyDateTimeTo
  );

  return (
    <Stack direction="row" gap="xs">
      {badges.filter(Boolean).map(item => {
        if (!validRange && item.id === 'app-exclusive') return <></>;
        return (
          <ProductBadge
            key={item.id}
            id={item.id as BadgeType}
            label={item.label}
          />
        );
      })}
    </Stack>
  );
};
